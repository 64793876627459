import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import Logo from '../../../dist/images/Login/logo.svg';
import arrow from '../../../dist/images/Login/leftarrow.png';
import Email from '../../../dist/images/Login/email.png';
import Google from '../../../dist/images/Login/google.png';
import Facebook from '../../../dist/images/Login/facebook.png';
import Apple from '../../../dist/images/Login/apple.png';
import Footer from '../Footer/Footer';
import LoginBottom from "./LoginBottom";
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import MetaGenerator from '../widgets/MetaGenerator';

// firebase signin
import firebase from "@firebase/app";
import "firebase/auth";
import firebaseConfig from './FirebaseConfig/firebase.config';

//API
import { SOCIAL_LOGIN, SOCIAL_REGISTER } from "../../../gql/Mutation";

export default function GuestLogin() {
    //const auth = firebase.auth();
    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }
    const guest_token = localStorage.getItem('guest_token')
    if (guest_token) {
        history.push("/")
    }
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 1);
        }, 100);
    }, [guest_token, history]);
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
    }
    const [socialLogin] = useMutation(SOCIAL_LOGIN,{
       onError(err) {
        const graphQLErrors = err.graphQLErrors

        if (graphQLErrors && graphQLErrors.length > 0) {
            const error = graphQLErrors[0]
            const extensions = error.extensions
            // Check if it's a validation error
            if (extensions && extensions.validation) {
            const validationErrors = extensions.validation
            // Loop through the validation errors and show each message in a toast
            Object.keys(validationErrors).forEach((key) => {
                validationErrors[key].forEach((message) => {
                toast.error(message)
                })
            })
            } else {
            // If it's a different type of error, show the general reason
            toast.error(extensions.reason)
            }
        } else {
            // Handle the case where there's no detailed GraphQL error
            toast.error('An unknown error occurred')
        }
    }}
    );

    const [socialRegister] = useMutation(SOCIAL_REGISTER,{
        onError(err) {
        const graphQLErrors = err.graphQLErrors

        if (graphQLErrors && graphQLErrors.length > 0) {
            const error = graphQLErrors[0]
            const extensions = error.extensions
            // Check if it's a validation error
            if (extensions && extensions.validation) {
            const validationErrors = extensions.validation
            // Loop through the validation errors and show each message in a toast
            Object.keys(validationErrors).forEach((key) => {
                validationErrors[key].forEach((message) => {
                toast.error(message)
                })
            })
            } else {
            // If it's a different type of error, show the general reason
            toast.error(extensions.reason)
            }
        } else {
            // Handle the case where there's no detailed GraphQL error
            toast.error('An unknown error occurred')
        }
        },
    });

    const registerSubmit = (result, provider) => {
        let first, last
        if (provider === "apple") {
            [first, last] = result.user.displayName.split(' ');
        }
         socialLogin({
            variables: {
                provider: provider,
                token: provider === "apple" ? result?.credential?.idToken : result?.credential?.accessToken
            }
        }).then(({ data }) => {
            if (data?.socialLogin?.user?.user_type === 2) {
                toast.success("Login successful!");
                localStorage.setItem('guest_token', data.socialLogin.access_token);
                localStorage.setItem('cz_type', data.socialLogin.user.user_type);
                history.push('/profile/my-profile');
            } else {
                socialRegister({
                    variables: {
                        first_name: provider === "google" ? result?.additionalUserInfo?.profile?.given_name : (provider === "apple" ? first : result?.additionalUserInfo?.profile?.first_name),
                        last_name: provider === "google" ? result?.additionalUserInfo?.profile?.family_name : (provider === "apple" ? last : result?.additionalUserInfo?.profile?.last_name),
                        mobile: "",
                        email: provider === "apple" ? result.user?.email : result?.additionalUserInfo?.profile?.email,
                        password: provider === "apple" ? result?.credential?.idToken : result?.credential?.accessToken,
                        provider: provider,
                        provider_id: provider === "apple" ? result.user.providerData[0].uid : result?.additionalUserInfo?.profile?.id
                    }
                }).then(({ data }) => {
                    // console.log(data.clientRegister.message);
                    if (data) {
                        if(data?.socialRegister?.status === 0){
                            toast.error(data?.socialRegister?.message)
                        }else{
                            socialLogin({
                                variables: {
                                    provider: provider,
                                    token: provider === "apple" ? result?.credential?.idToken : result?.credential?.accessToken
                                }
                            }).then(({ data }) => {
                                if (data?.socialLogin?.user?.user_type === 2) {
                                    toast.success("Login successful!");
                                    localStorage.setItem('guest_token', data.socialLogin.access_token);
                                    localStorage.setItem('cz_type', data.socialLogin.user.user_type);
                                    history.push('/profile/my-profile');
                                } else {
                                    toast.error(data?.socialLogin?.message);
                                }
                            })
                        }
                    }
                })
            }
        })
       
    }

    const handleGoogleLogin = async () => {
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().signInWithPopup(provider)
                .then(function (result) {
                    // console.log("Google", result?.additionalUserInfo?.profile?.family_name)
                    //console.log("result", result);
                    registerSubmit(result, "google");
                })
                .catch(function (error) {
                    const errorMessage = error.message;
                    console.log(errorMessage);
                });
        } catch (err) {
            console.log(err);
        }
    }
    const handleFacebookLogin = async () => {
        try {
            var fbProvider = new firebase.auth.FacebookAuthProvider();
            console.log("fbProvider", fbProvider);
            firebase.auth().signInWithPopup(fbProvider)
                .then(function (result) {
                    console.log("facebook", result?.additionalUserInfo?.profile?.family_name)
                    console.log("facebook");
                    registerSubmit(result, "facebook");
                })
                .catch(function (error) {
                    const errorMessage = error.message;
                    console.log(errorMessage);
                });
        } catch (err) {
            console.log(err);
        }
    }

    const handleAppleLogin = async () => {
        try {
            var appleProvider = new firebase.auth.OAuthProvider('apple.com');
            firebase.auth().signInWithPopup(appleProvider)
                .then(function (result) {
                    console.log("result", result);
                    registerSubmit(result, "apple");
                })
                .catch(e => console.log(e));
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <MetaGenerator
                title="Login"
            />
            <section id="Login">
                <div className="LoginWrap">
                    <div className="Overlay">
                        <div className="LoginInner">
                            <div className="LogoWrap justify-content-center">
                                {/* <span onClick={goToPreviousPath} className="BackHome">
                                    <img src={arrow} alt="icon" />
                                </span> */}
                                <Link to="/home" className="Logo">
                                    <img src={Logo} alt="Logo" />
                                </Link>
                                {/* <span></span> */}
                            </div>
                            <div className="LoginTop">
                                <span className="title">Log in for Guests</span>
                                <Link to="/login" className="LoginMail socio-btn">
                                    <img src={Email} alt="icon" />
                                    <span>Continue With Email</span>
                                </Link>
                                <p className="LoginGoogle socio-btn" onClick={handleGoogleLogin}>
                                    <img src={Google} alt="icon" />
                                    <span>Continue With Google</span>
                                </p>
                                <p className="LoginFacebook socio-btn" onClick={handleAppleLogin}>
                                    <img src={Apple} alt="icon" />
                                    <span>Continue With Apple</span>
                                </p>
                                <p className="LoginFacebook socio-btn" onClick={handleFacebookLogin}>
                                    <img src={Facebook} alt="icon" />
                                    <span>Continue With Facebook</span>
                                </p>
                                <div className="SignUp">
                                    <span>Don't have a account?</span>
                                    <Link to="/signup" className="SignUpBtn">
                                        Sign Up Now, Its Free!
                                    </Link>
                                </div>
                            </div>
                            <LoginBottom />
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </section>
        </>
    )
}
