import React, { createContext, useState, useEffect, useContext, Fragment, useMemo } from 'react';
import Basket from './Basket';
import { Modal, Spinner } from 'react-bootstrap';
import Product from "./Product";
import Vouchers from "./Vouchers";
import { toast } from 'react-toastify';
import arrow from '../../../../../dist/images/Login/colourLeft.png';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import BookingLogin from "./BookingLogin";
import SelectedService from "./SelectedService";
import DateTimeModal from "./DateTimeModal";
import PWays from "./PWays";
import { ContSerVcr } from "../../SingleShop";
import VettingCall from "./VettingCall"
// import BackTop from '../../../../../dist/images/singleShop/services/toparrow.png';
import { Scrollbars } from "react-custom-scrollbars-2";
import GetCurrency from "../../../widgets/GetCurrency";
import moment from "moment";

//API
import { MY_APPOINTMENTS, GUEST_STATUS, TIME_SLOT, TIME_SLOT_AVAILABILITY } from "../../../../../gql/Query";
import { BOOK_APPOINTMENT, SYSTEM_LOG, REVOLUT_PUBLIC_ID, } from "../../../../../gql/Mutation";
import useFormatServices, { useServicesFormater } from '../../../system/useFinalCartData';
import { totalPayable } from '../../../system/utility';
import useCartRawData from '../../../system/useCartRawData';
import usePrice from '../../../system/usePrice';
import { getDeviceInfo } from '../../../widgets/logUtilities';

export const cartList = createContext();

const ShopService = () => {
    const { serviceList, groupServices, singleShop, refetchShop, shopSlotDuration } = useContext(ContSerVcr);
    const today = new Date();
    // const myRef = useRef(null);
    // const executeScroll = () => myRef.current.scrollIntoView();
    const [isService, setIsService] = useState(false);
    const [onlyVoucher, setOnlyVoucher] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);
    const [loadPay, setLoadPay] = useState(false);
    const [closeDays, setCloseDays] = useState({})
    const [name, setName] = useState("");
    const [addBack, setAddBack] = useState(0);
    const [show, setShow] = useState(false);
    const [login, setLogin] = useState(false);
    const [summary, setSummary] = useState(false);
    console.log("🚀 ~ onAdd ShopService ~ summary:", summary)
    const [discount, setDiscount] = useState(0);
    const [payable, setPayable] = useState(0);
    const [paymentInfo, setPaymentInfo] = useState("");
    const [country, setCountry] = useState("");
    const [date, setDate] = useState(moment(today).format('YYYY-MM-DD'));
    const [time, setTime] = useState("");
    console.log("🚀 ~ ShopService ~ time:", time)
    const [tPriceString, setTPriceString] = useState({});
    const [bName, setBName] = useState("");
    const [videoVetting, setVideoVetting] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [onlyGroup, setOnlyGroup] = useState(null);
    const [currentCartItem, setCurrentCartItem] = useState({});
    const [voucherList, setVoucherList] = useState({});
    const [closeDate, setCloseDate] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalSPrice, setTotalSPrice] = useState(0);
    const [totalVPrice, setTotalVPrice] = useState(0);
    const [bID, setBID] = useState();
    const [upfront, setUpfront] = useState(0);
    const [currency, setcurrency] = useState("");
    const [guestStatus, setGuestStatus] = useState("");
    const [blockStatus, setBlockStatus] = useState(false);
    const [video_datetime, setVideo_datetime] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [blockedTime, setBlockedTime] = useState({});
    const [person, setPerson] = useState("");
    const [redeemMsg, setRedeemMsg] = useState("");
    const [applyRedeem, setApplyRedeem] = useState(0);
    // --------------------custome hook----------------------
    const formattedServices =  useFormatServices(cartItems, person);
    const formattedServicesOnly =  useServicesFormater(cartItems, person);
    const cartRaw =  useCartRawData(cartItems, person);
    const { price,sp_price,service_price,serviceAmount,voucherAmount,updatedTotalAmount,totalSpecialUpfront,totalServiceAmount } = usePrice(cartItems);
   
    //---------------------log data------------------
    const [system_log] = useMutation(SYSTEM_LOG,{
        onError(err) {
            toast.error(err.graphQLErrors[0].extensions.reason);
        }
    })
    //---------------------Guest status------------------------
    const { data: gStatus, refetch: refetchStatus } = useQuery(GUEST_STATUS, {
        variables: {
            business_id: parseInt(singleShop?.single_business?.business?.id)
        }
    });

    //-----------------Vetting call---------------
    const getVetDateTime = (t, d) => {
        setVideo_datetime(`${d} ${t}`);
        console.log('d === date', d)
        console.log('d === date raw cart item', cartRaw?.service[0]?.date)
        if (d === cartRaw?.service[0]?.date) {
            if (moment(cartRaw?.service[0]?.time, 'hh:mm a').isSameOrBefore(moment(t, 'hh:mm a'))) {
                toast.error("You must have to select consultation call time before the time you have selected for the services!");
                setConfirmBtn(true);
            } else {
                setConfirmBtn(false);
            }
        } else {
            if (moment(cartRaw?.service[0]?.date).isBefore(d)) {
                toast.error("You must have to select consultation call time before the time you have selected for the services!");
                setConfirmBtn(true);
            } else {
                setConfirmBtn(false);
            }
        }
    }

    //---------------------------select time--------------------------
    const { error: slotError, refetch: slotRefetch } = useQuery(TIME_SLOT, {
        variables: {
            business_id: parseInt(bID),
            date: date,
            services: ""
        }
    });

    //-----------------------get services and vouchers from Context API------------------
    useEffect(() => {
        if (singleShop) {
            if (singleShop?.single_business) {
                if (singleShop?.single_business?.business) {
                    setBID(singleShop.single_business.business.id);
                    setCountry(singleShop.single_business.business.country);
                    setUpfront(singleShop.single_business.business.upfront_amount);
                    setBName(singleShop.single_business.business.name);
                    setVideoVetting(singleShop.single_business.business?.video_vetting)
                }
                setVoucherList(singleShop?.single_business?.business_vouchers);
                setCloseDays(singleShop?.single_business?.close_weekday);
                setCloseDate(singleShop?.single_business?.close_date);
                setBlockedTime(singleShop?.single_business?.block_date);
            }
            if (singleShop?.me) {
                //console.log("singleShop?.me", singleShop?.me);
                setName(`${singleShop.me.first_name} ${singleShop.me.last_name}`);
            }
        }
        if (gStatus) {
            setGuestStatus(gStatus.guest_status?.status);
            setBlockStatus(gStatus.guest_status?.block)
            //console.log("gStatus-------------", gStatus)
        }
        if (slotError) {
            console.log("slotError", slotError)
        }
    }, [serviceList, singleShop, gStatus, slotError]);
    //----------get currency----------
    const c = GetCurrency(country)
    useEffect(() => {
        if (country && c) {
            setcurrency(c);
        }
    }, [country, c]);

    const [appointment_booking] = useMutation(BOOK_APPOINTMENT, {
        refetchQueries: [{
            query: MY_APPOINTMENTS,
            variables: {
                date_range: "",
                keyword: "",
                type: "upcoming",
                count: 10,
                page: 1
            }
        }],
        awaitRefetchQueries: true,
        onError(err) {
            console.log('on error', err);

            const graphQLErrors = err.graphQLErrors;

            if (graphQLErrors && graphQLErrors.length > 0) {
                const error = graphQLErrors[0];
                const extensions = error.extensions;
                // Check if it's a validation error
                if (extensions && extensions.validation) {
                    const validationErrors = extensions.validation;
                    // Loop through the validation errors and show each message in a toast
                    Object.keys(validationErrors).forEach((key) => {
                        validationErrors[key].forEach((message) => {
                            toast.error(message);
                        });
                    });
                    setLoadPay(false)
                    setConfirmBtn(false);
                } else {
                    // If it's a different type of error, show the general reason
                    toast.error(extensions.reason || "An unknown error occurred");
                    setLoadPay(false)
                    setConfirmBtn(false);
                }
            } else {
                // Handle the case where there's no detailed GraphQL error
                toast.error("An unknown error occurred");
            }
        }
    });

    //---------------------Revolut public ID-------------------
    const [revolutPaymentPublicId] = useMutation(REVOLUT_PUBLIC_ID);

    //----------------checking for time slot availity------------------
    // const [timeslot_availability] = useMutation(TIME_SLOT_AVAILABILITY);
    const [timeslot_availability] = useLazyQuery(TIME_SLOT_AVAILABILITY);

    //---------------select any of the payment ways-----------
    const onPaySelect = (type, withVoucher) => {
        setPaymentType(type);
        console.log("onPaySelect ~ withVoucher:", withVoucher)
        console.log("onPaySelect ~ type:", type)
        if (withVoucher > 0) {
            if (type === "stripe" || type === "revolut") {
                setConfirmBtn(false);
                let infoData = paymentInfo
                if (type === "stripe") {
                    infoData = { ...infoData, stripe: applyRedeem.toString(), revolut: "" };
                    setPaymentInfo(infoData);
                }
                if (type === "revolut") {
                    infoData = { ...infoData, revolut: applyRedeem.toString(), stripe: "" };
                    setPaymentInfo(infoData);
                }
            }
        } else {
            if (type === "stripe") {
                setConfirmBtn(false);
                if (isService) {
                    setPaymentInfo({ voucher: {}, stripe: payable.toString(), revolut: "" });
                } else {
                    setPaymentInfo({ voucher: {}, stripe: totalVPrice.toString(), revolut: "" });
                }
            }
            if (type === "revolut") {
                setConfirmBtn(false);
                if (isService) {
                    setPaymentInfo({ voucher: {}, stripe: "", revolut: payable.toString() });
                } else {
                    setPaymentInfo({ voucher: {}, stripe: "", revolut: totalVPrice.toString() });
                }
            }
        }
    }

    //------------payment info for voucher redeem-----------------
    const redeem = (code, v, s) => {
        console.log("code, v, s", code, v)
        if (v > 0) {
            if (s === 0) {
                setPaymentInfo({
                    voucher: { code: code, payment: v.toString() }, stripe: "", revolut: ""
                });
                setConfirmBtn(false);
                setRedeemMsg("");
            } else {
                setRedeemMsg(`You can use ${currency}${v} from the voucher. You have to pay rest of the amount ${currency}${s.toFixed(2)} to confirm booking. Please select your payment method!`);
                setApplyRedeem(s);
                setPaymentInfo({
                    voucher: { code: code, payment: v.toString() }, stripe: "", revolut: ""
                });
            }
            setTPriceString({ service: totalServiceAmount.toString(), voucher: totalVPrice.toString() })
        } else {
            setPaymentInfo({ voucher: {}, stripe: (voucherAmount + (serviceAmount * upfront / 100)).toFixed(2), revolut: "" });
        }
    }

    //-------------------selected cartItems data--------------------
    // console.log("sp_price", sp_price)
    useEffect(() => {
        if (cartItems) {
            var pybl
            if(upfront > 0 && totalSpecialUpfront == 0){
                pybl = voucherAmount + +totalPayable(serviceAmount, upfront) + +totalSpecialUpfront;                
                setPayable(parseFloat(pybl))
                setPaymentInfo({ voucher: {}, stripe: "", revolut: "" })
            }
            else if(totalSpecialUpfront > 0 && guestStatus === "New"){
                pybl =  +totalSpecialUpfront;
                // pybl = voucherAmount + +totalPayable(serviceAmount, upfront) + +totalSpecialUpfront;
                setPayable(parseFloat(pybl))          
                setPaymentInfo({ voucher: {}, stripe: totalSpecialUpfront ? totalSpecialUpfront.toString(): "", revolut: totalSpecialUpfront ? totalSpecialUpfront.toString() : totalSpecialUpfront })
            }
            else if (upfront > 0 || totalSpecialUpfront > 0) {
                pybl = voucherAmount + +totalPayable(serviceAmount, upfront) + +totalSpecialUpfront;               
                setPayable(parseFloat(pybl))
                setPaymentInfo({ voucher: {}, stripe: pybl? pybl.toString() : "", revolut:pybl? pybl.toString(): "" })
            } else {
                setPaymentInfo({ voucher: {}, stripe: "", revolut: "" })
                setPayable(0.0)
            }
            // console.log("price - serviceAmount", price, serviceAmount)
            if (price > totalServiceAmount) {
                setDiscount(price - totalServiceAmount);
            } else {
                setDiscount(0);
            }
            // setSubTotal(sp_price);           
            setTotalPrice(sp_price);
            setTotalSPrice(serviceAmount)
            setTotalVPrice(voucherAmount)
            setTPriceString({ service: totalServiceAmount.toString(), voucher: voucherAmount.toString() });
            if (cartItems) {
                for (const item of cartItems) {
                    if (item.__typename === "ServicePricing") {
                        setIsService(true);
                        break;
                    } else {
                        setIsService(false);
                    }
                }
            }
        }
        console.log("ShopService ~ useEffect ~ pybl:", pybl)
        if(!cartItems?.length){
            setPaymentType('')
            setOnlyVoucher(false)
        }
    }, [cartItems, price, sp_price, serviceAmount, voucherAmount, totalServiceAmount, totalSpecialUpfront, upfront])

    const guest_token = localStorage.getItem('guest_token');
    const handleClose = () => {
        setShow(false);
        setSummary(false)
        setLogin(false)
        setTime("");
        // if(guest_token && login){
        //     setSummary(true);
        // }else{
        //     setSummary(false);
        // }
    };
    const handleShow = () => {
        // console.log("🚀 ~ onAdd ~ handleShow clicked")
        setShow(true);
        // setSummary(false);
    }

    //---------------------------------Add to cart------------------------------
    const onAdd = (product, person) => {
        if (product?.__typename === "Voucher") {
            setCartItems([...cartItems, { ...product, qty: 1, person: person }]);
        } else {
            if(product.group_type === "repeated"){
                if(product.special_price >0){
                    setCartItems([...cartItems,{ ...product, qty: product.occurrences, special_price: product.occurrences * product.special_price, date: date, time: time }]);
                }else{
                    setCartItems([...cartItems,{ ...product, qty: product.occurrences, price: product.occurrences * product.price, date: date, time: time }]);
                }
            }else{
                setCartItems([...cartItems, { ...product, qty: product.occurrences, date: date, time: time }]);
            }
            toast.success("Added to cart!");
            handleClose();

        }
    };

    //------------------------Increment------------------------------
    const inc = (id, name) => {
        // console.log("id", id)
        const updatedCart = cartItems.map((curElem) => {
            if (curElem.id === id && curElem.name === name) {
                return { ...curElem, qty: curElem.qty + 1 };
            }
            return curElem;
        });
        // console.log("updatedCart", updatedCart)
        setCartItems(updatedCart);
    };

    //-------------------------------Decrement------------------------------------
    const dec = (id, name) => {
        const updatedCart = cartItems
            .map((curElem) => {
                if (curElem.id === id && curElem.name === name) {
                    return { ...curElem, qty: curElem.qty - 1 };
                }
                return curElem;
            })
            .filter((curElem) => curElem.qty != 0);
        setCartItems(updatedCart);
    };

    //--------------------Remove from cart---------------------
    const onRemove = (product) => {
        setAddBack(product.id)
        setCartItems(cartItems.filter((x) => x.id !== product.id));
        toast.warning("Removed from the cart!");
    };

    //--------------------- cancellation list---------------------
    const canList = () => {
        setShow(false);
        setCartItems([]);
    }

    // console.log("date, time", date, time);
    // ---------------guest status checking ----------------
    const getAuth = (val) => {
        refetchStatus();
        setSummary(val);
        setLogin(val);
    }
    // ------------------geust loing checking ------------------
    // const checkAuth = (time, date) => {
    //     setTime(time);
    //     setDate(date);
    //     if (!guest_token) {
    //         toast.info("Please login first!");
    //         setLogin(true)
    //     } else {
    //         console.log("🚀 ~cart checkAuth ~ checkAuth:", summary)
    //         setSummary(true);
    //     }
    // }

    // --------------popup summary to date time calender back button --------
    const goBack = (val) => {
        setLogin(val)
    }
  
    // --------------slot availability checking -----------------
    const checkAvailability = () => {
        const chekingParams= {
            business_id: parseInt(bID),
            services: formattedServicesOnly.service
        } 
        timeslot_availability({
            variables: chekingParams
        })
        // .then(({ data }) => {
        //     const {message, status} = data?.timeslot_availability;
        //     if (status === 1) {
        //         // checkAuth(time, date);
        //     } else if(status === 0) {
        //         if(message === "Video call"){
        //             //here toast for video call
        //             // toast.info(message) // have an issue api call without service id
        //         }else{
        //             toast.info(message)
        //         }
        //     }
        // })
        // .catch(e => {
        //     toast.error("Internal server error!")
        //     // toast.error(e.message)
        //     console.log("error", e);
        // })
    }

    // checkout handle voucher and service
    const handleCheckout=()=>{
        if (!guest_token) {
            toast.info("Please login first!");
            setLogin(true);
            handleShow(); 
        } else {
            handleShow(); 
            setOnlyVoucher(false) ;
            setSummary(true)
            checkAvailability();
            // if only group is true then ignore vetting call for first time new guest
            setOnlyGroup(cartItems.every((service) => service.is_group));
        }
    }
    // checkout handle only voucher
    const  handleCheckoutOnlyVoucher=()=>{
        handleShow(); 
        setOnlyVoucher(true);
        checkAvailability(); 

    }
    useMemo(() => (time !== "" ? checkAvailability() : null), [time]);

    const confirmPurchase = () => {
        const token = localStorage.getItem("guest_token");
        if (token) {
            if (!blockStatus) {
                setLoadPay(true);
                setConfirmBtn(true);
                const appointment_details = {
                        services: cartRaw.service ?? [], //ok
                        vouchers: cartRaw.voucher?? [], //ok
                        business_id: parseInt(bID), //ok
                        total_amount: tPriceString, //ok
                        payment_info: paymentInfo,
                        upfront_amount: payable.toString(),
                        discount: discount? discount.toString(): "",
                        video_datetime: video_datetime,
                        booking_request: 'web'
                }
                appointment_booking({
                    variables: appointment_details
                }).then(({ data }) => {
                    const bookingPayload = {
                        api: 'booking_confirmation',
                        user: guest_token,
                        body: JSON.stringify(appointment_details),
                        response: "",
                        exception: "",
                        source: 'web-guest',
                        version: '1.1.0',
                        priority: 'high',
                        device: getDeviceInfo(),
                    }
                    if (data?.appointment_booking) {
                        const {status, message, sale_id, payment_url, payment_amount} = data.appointment_booking;
                        if (status === 1) {
                            if (date !== "") {
                                slotRefetch();
                            }
                            setShow(false);
                            setSummary(false);
                            setTimeout(() => {
                                setTime("");
                                setDate("");
                            }, 1000);
                            setCartItems([]);
                            setLoadPay(false);
                            setApplyRedeem(0);
                            setRedeemMsg("");
                            toast.success(message);
                            if (payment_url) {
                                window.open(payment_url, "__blank");
                                refetchShop()
                                setPaymentType("")
                            } else {
                                if (paymentType === "revolut") {
                                    revolutPaymentPublicId({
                                        variables: {
                                            amount: payment_amount,
                                            currency: "EUR"
                                        }
                                    }).then(({ data }) => {
                                        //console.log("public key", data?.revolutPaymentPublicId?.id);
                                        if (data?.revolutPaymentPublicId) {
                                            window.open(`/revolut/${data?.revolutPaymentPublicId?.public_id}/${payment_amount}/${sale_id}/${data?.revolutPaymentPublicId?.id}`, "_blank");
                                            refetchShop();
                                            setPaymentType("")
                                        }
                                    }).catch(e => {
                                        system_log({
                                            variables:{...bookingPayload, exception: JSON.stringify(e)}
                                        })
                                        console.log("revolut error", e)
                                    })
                                }
                                if (paymentType === "") {
                                    toast.success("Booking confirmed!");
                                    refetchShop();
                                }
                            }
                        }else if(status === 0){
                            setLoadPay(false)
                            setConfirmBtn(false);
                             system_log({
                                variables:{...bookingPayload, response: JSON.stringify(message)}
                            })
                            toast.error(message);
                        }
                        else {
                             system_log({
                                variables:{...bookingPayload, response: JSON.stringify(message)}
                            })
                            
                            toast.error(message);
                            //console.log(data.appointment_booking.message, data)
                        }
                        setLoadPay(false)
                        setConfirmBtn(false);
                    }
                })

            } else {
                toast.error("You have been blocked by the business!")
            }
        } else {
            window.location.reload();
        }
    }
    // console.log("vetting guestStatus", guestStatus)
    // console.log("vetting name", name) 
    // console.log("vetting !onlyGroup", onlyGroup)
    // console.log("vetting  singleShop?.single_business?.business?.video_vetting", singleShop?.single_business?.business?.video_vetting)
    return (
        <cartList.Provider value={{cartItems, person, handleShow, currentCartItem, setCurrentCartItem,  
        date, setDate, time, setTime}}>
            <div id="ShopServicesRoot">
                <div className="container">
                    <div className="row">
                            <div className="col-lg-7 pe-md-0">
                            {/* one to one service list */}
                            {serviceList && serviceList?.length > 0 &&
                            <Fragment>
                                <h2 className="sectionHeading">Book A Service</h2>
                                <div className="servicesItemWrap">
                                    {
                                        serviceList?.map((item) => (
                                            <Product
                                                key={item.id}
                                                content={item}
                                                onAdd={onAdd}
                                                addBack={addBack}
                                                currency={currency}
                                                defaultCartIcon={true}
                                            />
                                        ))
                                    }
                                </div>
                            </Fragment>}
                            {/* group and course service list */}
                            {
                                groupServices && groupServices?.length > 0 &&
                            <Fragment>
                                <h2 className="sectionHeading">Book A Group or Course</h2>
                                <div className="servicesItemWrap">
                                    {
                                        groupServices.map((item) => (
                                            <Product
                                                key={item.id}
                                                content={item}
                                                onAdd={onAdd}
                                                addBack={addBack}
                                                currency={currency}
                                                defaultCartIcon={false}
                                            />
                                        ))
                                    }
                                </div>
                            </Fragment>}
                            {/* --------------------Vouchers------------------------ */}
                            {
                                voucherList?.length > 0 &&
                                <div className='mt-5'>
                                    <h2 className="sectionHeading">Vouchers</h2>
                                    <div className='row servicesItemWrap'>
                                        {
                                            voucherList?.map((item) => (
                                                <Vouchers
                                                    key={item.id}
                                                    content={item}
                                                    onAdd={onAdd}
                                                    addBack={addBack}
                                                    profileName={name}
                                                    currency={currency}
                                                    person={person}
                                                    setPerson={setPerson}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {/* cart section */}
                        <div className="col-lg-5">
                            <div className="servicesCartWrap">
                                <section id="cart">
                                    <div className="cartInner">
                                        <h3>{bName ? bName : ""}</h3>
                                        <h2>My Cart</h2>
                                        {cartItems?.length === 0 ?
                                            <div className='Category'> <div className="default-text">Cart is empty</div></div>
                                            :
                                            cartItems?.length > 3 ?
                                                <div className='cartItems'>
                                                    <Scrollbars>
                                                        <>
                                                            {/* <div ref={myRef}></div> */}
                                                            {
                                                                cartItems?.map((item) => (
                                                                    <Basket
                                                                        key={item.id}
                                                                        content={item}
                                                                        onAdd={onAdd}
                                                                        onRemove={onRemove}
                                                                        inc={inc}
                                                                        dec={dec}
                                                                        currency={currency}
                                                                    />
                                                                ))
                                                            }
                                                        </>
                                                    </Scrollbars>
                                                </div> :
                                                cartItems?.map((item) => (
                                                    <Basket
                                                        key={item.id}
                                                        content={item}
                                                        onAdd={onAdd}
                                                        onRemove={onRemove}
                                                        inc={inc}
                                                        dec={dec}
                                                        currency={currency}
                                                    />
                                                ))
                                        }
                                        {
                                            discount > 0 &&
                                            <div className="cartVat">
                                                <span className="vatinfo">Discount</span>
                                                <div className="vatamonunt">
                                                    <span className='h3 mt-2 ms-2'>{currency}</span>
                                                    <span>{discount}</span>
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            {cartItems.length !== 0 && (
                                                <>
                                                    {
                                                        upfront > 0 && isService &&
                                                        <div className="cartVat">
                                                            <span className="vatinfo">Deposit amount</span>
                                                            <div className="vatamonunt">
                                                                <span>{upfront}%</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="totalCost">
                                                        <span>Total Cost</span>
                                                        <div className="vatamonunt">
                                                            <span className='h3 mt-2 ms-2'>{currency}</span>
                                                            <span>{totalPrice}</span>
                                                        </div>
                                                    </div>
                                                    <h6>*vat included</h6>
                                                </>
                                            )}
                                        </div>
                                        {/* {
                                            cartItems?.length > 3 &&
                                            <div className="arrowTop" onClick={executeScroll}>
                                                <img src={BackTop} alt="icon" />
                                            </div>
                                        } */}
                                        <div className="cartCheckBtn venuItem mt-3 text-center">
                                            {
                                                isService ?
                                                    <button onClick={() => {handleCheckout()}} 
                                                    className="primaryBtn btn mx-auto" disabled={cartItems?.length > 0 ? false : true}>
                                                        <span>Next <i className="arrow fa fa-angle-right"></i></span>
                                                    </button> :
                                                    <button onClick={() => { handleCheckoutOnlyVoucher()}} className="primaryBtn btn mx-auto" disabled={cartItems?.length > 0 ? false : true}>
                                                        <span>Next <i className="arrow fa fa-angle-right"></i></span>
                                                    </button>
                                            }
                                            <Modal size="md" aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose}>
                                                {!summary ?
                                                    (!login ?
                                                        <>
                                                            {!isService && onlyVoucher ?
                                                                <>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title className='BackHome'>
                                                                            {/* <span onClick={() => { setSummary(false) }} className="BackHome">
                                                                                <img src={arrow} alt="icon" />
                                                                            </span> */}
                                                                            <span>Confirm Appointment</span>
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        {
                                                                            guestStatus &&
                                                                            <>
                                                                                <SelectedService
                                                                                    cartItems={cartItems}
                                                                                    voucherAmount={totalVPrice}
                                                                                    serviceAmount={totalSPrice}
                                                                                    totalServiceAmount={totalServiceAmount}
                                                                                    totalSpecialUpfront={totalSpecialUpfront}
                                                                                    upfront={upfront}
                                                                                    currency={currency}
                                                                                    guestStatus={guestStatus}
                                                                                    vettingCall={videoVetting}
                                                                                    onlyGroup = {onlyGroup}
                                                                                />
                                                                                {blockStatus ?
                                                                                    <div>
                                                                                        <p className='text-danger text-center mt-3'>
                                                                                            You have been blocked by this business!
                                                                                        </p>
                                                                                    </div> :
                                                                                        <PWays
                                                                                            serviceAmount={totalSPrice}
                                                                                            upfront={ upfront }
                                                                                            totalServiceAmount={totalServiceAmount}
                                                                                            totalSpecialUpfront={totalSpecialUpfront}
                                                                                            specialDepositePayWithVettingCall= {false}
                                                                                            redeem={redeem}
                                                                                            onPaySelect={onPaySelect}
                                                                                            currency={currency}
                                                                                            redeemMsg={redeemMsg}
                                                                                            applyRedeem={applyRedeem}
                                                                                            totalVPrice={totalVPrice}
                                                                                            paymentType={paymentType}
                                                                                        />
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Modal.Body>
                                                                    <div className="select-time-btn-wrap pb-2">
                                                                        <button 
                                                                            disabled={
                                                                                (upfront > 0 || +totalSpecialUpfront > 0) 
                                                                                ? (paymentType === "" || confirmBtn) 
                                                                                : false
                                                                            } 
                                                                            onClick={() => { confirmPurchase() }} 
                                                                            className="btn primaryBtn"
                                                                        >
                                                                            {loadPay ? (
                                                                                <>
                                                                                    <span className='me-2'>
                                                                                        Please wait...
                                                                                    </span>
                                                                                    <span>
                                                                                        <Spinner animation="border" variant="white" />
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                <span>
                                                                                    Confirm  <i className="arrow fa fa-angle-right" />
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>

                                                                </> : <DateTimeModal
                                                                    closeDays={closeDays}
                                                                    bID={bID}
                                                                    onAdd={onAdd}
                                                                    // checkAuth={checkAuth}
                                                                    shopSlotDuration={shopSlotDuration}
                                                                    closeDate={closeDate}
                                                                    canList={canList}
                                                                    vetting={false}
                                                                    formatServices={""}
                                                                    blockedTime={blockedTime}
                                                                    notification={false}
                                                                />
                                                            }
                                                        </> :
                                                        (cartItems && totalPrice) &&
                                                        <>
                                                            {/* <BookingLogin getAuth={getAuth} goBack={goBack} date={date} time={time} cartItems={cartItems} totalPrice={totalPrice} upfront={upfront} /> */}
                                                            <BookingLogin
                                                                getAuth={getAuth}
                                                                goBack={goBack}
                                                                date={date}
                                                                time={time}
                                                                cartItems={cartItems}
                                                                upfront={upfront}
                                                                totalVPrice={totalVPrice}
                                                                totalSPrice={totalSPrice}
                                                                currency={currency}
                                                                guestStatus={guestStatus}
                                                                videoVetting={videoVetting}
                                                                setName={setName}
                                                            />
                                                        </>
                                                    ) :
                                                    <>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title className='BackHome'>
                                                                {/* <span onClick={() => { setSummary(false) }} className="BackHome">
                                                                    <img src={arrow} alt="icon" />
                                                                </span> */}
                                                                <span>Confirm Appointment</span>
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <SelectedService
                                                                cartItems={cartItems}
                                                                voucherAmount={totalVPrice}
                                                                serviceAmount={totalSPrice}
                                                                totalServiceAmount={totalServiceAmount}
                                                                totalSpecialUpfront={totalSpecialUpfront}
                                                                upfront={upfront}
                                                                currency={currency}
                                                                guestStatus={guestStatus}
                                                                vettingCall={videoVetting}
                                                                onlyGroup={onlyGroup}
                                                            />
                                                            {
                                                                blockStatus ?
                                                                    <div>
                                                                        <p className='text-danger text-center mt-3'>You have been blocked by this business!</p>
                                                                    </div> 
                                                                    :
                                                                    ((guestStatus === "New" && singleShop?.single_business?.business?.video_vetting) && name && !onlyGroup?
                                                                        <Fragment>
                                                                            <VettingCall
                                                                                closeDays={closeDays}
                                                                                bID={bID}
                                                                                // checkAuth={checkAuth}
                                                                                closeDate={closeDate}
                                                                                blockedTime={blockedTime}
                                                                                canList={canList}
                                                                                getVetDateTime={getVetDateTime}
                                                                                video_datetime={video_datetime}
                                                                                name={name}
                                                                                bName={bName}
                                                                            />
                                                                            {
                                                                                +totalSpecialUpfront > 0 ? 
                                                                                <PWays
                                                                                    serviceAmount={totalSPrice}
                                                                                    upfront={ +totalSpecialUpfront }
                                                                                    specialDepositePayWithVettingCall= {true}
                                                                                    redeem={redeem}
                                                                                    onPaySelect={onPaySelect}
                                                                                    currency={currency}
                                                                                    redeemMsg={redeemMsg}
                                                                                    applyRedeem={applyRedeem}
                                                                                    totalVPrice={totalVPrice}
                                                                                    paymentType={paymentType}
                                                                                />
                                                                                :null

                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        (cartRaw?.voucher?.length > 0 || upfront > 0 || +totalSpecialUpfront > 0 ) &&
                                                                        <PWays
                                                                            serviceAmount={totalSPrice}
                                                                            totalServiceAmount={totalServiceAmount}
                                                                            totalSpecialUpfront={totalSpecialUpfront}
                                                                            specialDepositePayWithVettingCall= {false}
                                                                            upfront={upfront}
                                                                            redeem={redeem}
                                                                            onPaySelect={onPaySelect}
                                                                            currency={currency}
                                                                            redeemMsg={redeemMsg}
                                                                            applyRedeem={applyRedeem}
                                                                            totalVPrice={totalVPrice}
                                                                            paymentType={paymentType}
                                                                        />)
                                                            }
                                                        </Modal.Body>
                                                        <div className="select-time-btn-wrap pb-2">
                                                            <button 
                                                                disabled={
                                                                    (cartRaw?.voucher?.length > 0 || upfront > 0 || +totalSpecialUpfront > 0) 
                                                                    ? (confirmBtn) 
                                                                    : false
                                                                } 
                                                                onClick={() => { confirmPurchase() }} 
                                                                className="btn primaryBtn"
                                                            >
                                                                {loadPay ? (
                                                                    <>
                                                                        <span className='me-2'>
                                                                            Please wait...
                                                                        </span>
                                                                        <span>
                                                                            <Spinner animation="border" variant="white" />
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <span>
                                                                        Confirm  <i className="arrow fa fa-angle-right" />
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>

                                                    </>
                                                }
                                            </Modal>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </cartList.Provider>
    );
}

export default ShopService;